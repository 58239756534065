<template>
	<div>
		<desModal :titleRight="showOftenPassenger" widthNum="86%" :footerNone="false" :showClose="false"
			@clickHeadAction="bindSelectPassenger" @clickDelAction="hideSelectPassengerBox" title="常用旅客"
			:delShow="true">
			<div style="positiion:relative" class="addperson">

				<loading v-if="showLoading"></loading>
				<el-table height="400" ref="multipleTable" :data="PassengerList" tooltip-effect="dark"
					style="width: 100%" @selection-change="handleSelectionChange">
					<el-table-column type="selection" width="55"></el-table-column>
					<el-table-column v-for="(item, index) in outData" :key="index" :prop="item.prop" :label="item.label"
						:width="item.width">
						<template slot-scope="scope">
							<div v-if="index == 0">
								<!-- 中文名：{{ scope.row.FullName }} -->
								<div v-if="scope.row.FullName != ''">
									中文名：{{ scope.row.FullName }}
								</div>
								<div v-if="scope.row.FirstNameEn != '' && scope.row.LastNameEn != ''">
									英文名：{{ scope.row.FirstNameEn }}/{{ scope.row.LastNameEn }}
								</div>

								<!-- {{ scope.row.FirstNameEn }}/{{ scope.row.LastNameEn }}
							<br />
							中文名：{{ item.FullName }} -->
							</div>
							<div v-if="index == 1">{{ scope.row.Sex }}</div>
							<div v-if="index == 2">{{ scope.row.Nationality }}</div>
							<div v-if="index == 3">{{ scope.row.BirthDay }}</div>
							<div v-if="index == 4&&scope.row.WXCredentials.length>0">
								{{ scope.row.WXCredentials[0].ProofName }}</div>
							<div v-if="index == 5&&scope.row.WXCredentials.length>0">
								{{ scope.row.WXCredentials[0].Number }}</div>
							<div v-if="index == 6&&scope.row.WXCredentials.length>0">
								{{ scope.row.WXCredentials[0].EndDate }}</div>
							<div v-if="index == 7">
								{{ scope.row.Phone }}</div>
							<div v-if="index == 8">
								<el-button type="warn" @click="openEditPessenger(scope.row.PersonCode)"><span>编辑</span>
								</el-button>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="text-c" style="padding-bottom: 40px;">
				<span slot="footer" class="dialog-footer">
					<el-button type="warn" class="add-person-btn mr15" @click="openEditPessenger('')"><span>添加旅客</span>
					</el-button>
					<el-button class="mr15" @click="hideSelectPassengerBox">取 消</el-button>
					<el-button type="danger" class="button mt25" style="width: 100px;" @click="bindSelectPassenger">确 定
					</el-button>
				</span>
			</div>
		</desModal>

		<desModal :titleRight="showChoose" :footerNone="false" :showClose="false"
			:title="(editPesonCode==''?'添加':'编辑')+'旅客'" :delShow="false">
			<div>
				<el-row type="flex" align="middle">
					<span class="mr10 type-left">证件类型:</span>
					<el-select v-model="proofItem" clearable placeholder="请选择" @change="selectChanged"
						style="width:380px">
						<el-option v-for="item in CertificateTypeList" :key="item.value" :label="item.name"
							:value="item">
						</el-option>
					</el-select>
				</el-row>

				<block v-if="proofItem.value!='ID'">
					<el-row type="flex" align="middle" class="mt10">
						<span class="mr10 type-left">姓（拼音）:</span>
						<el-input v-model="MPassengerInfo.LastNameEn" placeholder="例（ZHANG）" style="width:380px">
						</el-input>
					</el-row>
					<el-row type="flex" align="middle" class="mt10">
						<span class="mr10 type-left">名（拼音）:</span>
						<el-input v-model="MPassengerInfo.FirstNameEn" placeholder="例（SAN）" style="width:380px">
						</el-input>
					</el-row>
				</block>
				<block v-else>
					<el-row type="flex" align="middle" class="mt10">
						<span class="mr10 type-left">姓名:</span>
						<el-input v-model="MPassengerInfo.FullName" placeholder="例：张三" style="width:380px"></el-input>
					</el-row>
				</block>


				<div class="gray9 mt10" style="padding-left:132px">注：所填姓名必须与所选证件上姓名保持一致</div>
				<el-row type="flex" align="middle" class="mt10">
					<span class="mr10 type-left">证件号码:</span>
					<el-input v-model="proofItem.number" placeholder="请输入证件号码" style="width:380px"
						@blur="bindCheckCerNo()"></el-input>
				</el-row>

				<el-row v-if="proofItem.value!='ID' && proofItem.value!='10' && proofItem.value!='NI'" type="flex"
					align="middle" class="mt10">
					<span class="mr10 type-left">证件有效期:</span>
					<el-date-picker v-model="proofItem.enddate" value-format="yyyy-MM-dd" type="date"
						style="width:380px" placeholder="选择日期">
					</el-date-picker>
				</el-row>
				<el-row type="flex" align="middle" class="mt10">
					<span class="mr10 type-left">手机号码:</span>
					<el-input v-model="mobile" placeholder="请输入证件号码" style="width:380px"
						></el-input>
				</el-row>
				<el-row v-if="proofItem.value=='ID'" type="flex" align="middle" class="mt10">
					<span class="mr10 type-left">出生日期:</span>
					<el-date-picker v-model="BirthDay" value-format="yyyy-MM-dd" style="width:380px" type="date"
						placeholder="选择日期">
					</el-date-picker>
				</el-row>

				<el-row v-if="proofItem.value!='ID' && proofItem.value!='10' && proofItem.value!='NI'" type="flex"
					align="middle" class="mt10 mb10">
					<span class="mr10 type-left">性别:</span>
					<el-select v-model="MPassengerInfo.Sex" clearable placeholder="请选择" style="width:380px">
						<el-option label="男" :value="1"></el-option>
						<el-option label="女" :value="0"></el-option>
					</el-select>
				</el-row>
			</div>
			<div class="text-c" style="padding-bottom: 40px;">
				<span slot="footer" class="dialog-footer">
					<el-button @click="showChoose = false">取 消</el-button>
					<el-button type="danger" class="button mt25" style="width: 100px;" @click="bindSubmitEditPassenge"
						:loading="btnLoading">提交</el-button>
				</span>
			</div>
		</desModal>
	</div>
</template>

<script>
	import loading from './loading.vue';
	import desModal from './desModal.vue';
	// import { ref } from 'vue';

	import {
		getTicketSearchInfo,
		setTicketSearchInfo,
		getAccountInfo,
		getBookingFormInfo,
		setBookingFormInfo
	} from '@/utils/localdatas';

	var util = require('@/utils/util.js');
	var yqfCommon = require('@/utils/yqfCommon.js'); //通用类库
	var memberApi = require('@/api/memberApi.js');

	var that;

	export default {
		name: 'selectPassenger',
		components: {
			loading,
			desModal
		},
		mounted() {
			// console.log(this.$store.state.tableData.outData);
			// console.log(this.$store.state.tableData.tableData);
		},
		props: ['showOftenPassenger', 'IsChinaCity'],
		setup(props) {},
		data() {
			return {
				showLoading: false,
				searchText: '',
				showChoose: false,

				sexArr: [{
					value: '1',
					label: '男'
				}, {
					value: '0',
					label: '女'
				}],
				value2: '',
				outData: [{
						prop: 'name',
						label: '姓名',
						width: '180'
					},
					{
						prop: 'sex',
						label: '性别',
						width: '80'
					},
					{
						prop: 'country',
						label: '国籍',
						width: '100'
					},
					{
						prop: 'date',
						label: '出生日期',
						width: '165'
					},
					{
						prop: 'idType',
						label: '证件类型',
						width: '140'
					},
					{
						prop: 'idnumber',
						label: '证件号',
						width: '180'
					},
					{
						prop: 'exp',
						label: '证件有效期',
						width: '165'
					},
					{
						prop: 'phone',
						label: '国家区号-手机号码',
						width: '220'
					},
					{
						prop: '',
						label: '',
						width: '120'
					}
				],
				PassengerList: [],

				multipleSelection: [],
				accountInfo: '',
				CertificateTypeList: [], //证件类型
				CertificateTypeIndex: 0,
				MPassengerInfo: '',
				proofItem: '',
				BirthDay: '',
				editPesonCode: '',
				btnLoading: false,
				isChinaCity: this.IsChinaCity,
				mobile:''
			};
		},
		created() {
			that = this;
			var query = that.$route.query;
			console.log(query);

			that.accountInfo = getAccountInfo();
			
			if (that.accountInfo != null) {
				getOftenPassengerList();
			}
			console.log(that.isChinaCity);

		},
		methods: {
			selectChanged(val) {
				console.log(val);
				that.proofItem = val;
				// that.CertificateTypeIndex = e;

				// that.proofItem = that.CertificateTypeList[that.CertificateTypeIndex];

				// var temp_Arr = that.CertificateTypeList.filter(function(item_list) {
				// 	return item_list.vaule === val;
				// });
				// that.proofItem=temp_Arr;
				// console.log(that.proofItem);
			},

			hideSelectPassengerBox() {
				// this.showWorkerValue = false;
				that.$emit('hideSelectPassengerBox');
			},
			//新增/编辑旅客
			openEditPessenger(val) {
				that.btnLoading = false;
				that.showChoose = true;
				that.editPesonCode = val;
				getMPassengerInfo(val);
			},
			//确定
			bindSelectPassenger(e) {
				// this.showWorkerValue = false;
				that.$emit('bindSelectPassenger', that.multipleSelection);
			},
			hideSelectPassengerBoxt() {
				that.showChoose = false;
			},

			toggleSelection(rows) {
				if (rows) {
					rows.forEach(row => {
						that.$refs.multipleTable.toggleRowSelection(row);
					});
				} else {
					that.$refs.multipleTable.clearSelection();
				}
			},
			handleSelectionChange(val) {
				that.multipleSelection = val;
				console.log(that.multipleSelection);
			},
			//检查证件
			bindCheckCerNo() {
				let number = that.proofItem.number;
				console.log(number);

				let cardInfo = yqfCommon.getIDCardInfo(number);
				console.log(cardInfo);
				if (cardInfo != null) {
					let Man_type_p = "ADT";
					if (cardInfo.age < 18) {
						Man_type_p = 'CHD';
					} else {
						Man_type_p = 'ADT';
					}
					that.MPassengerInfo.PassengerAgeSection = Man_type_p;
					that.BirthDay = cardInfo.birthday;
					that.MPassengerInfo.Sex = cardInfo.sex == '男' ? '1' : '0';
				}

			},
			//提交保存
			bindSubmitEditPassenge(e) {
				that.btnLoading = true;
				let CertificateType = JSON.stringify(that.proofItem);
				let MPassengerInfo = that.MPassengerInfo;
				let parm = {

					CertificateType: CertificateType,
					Account: that.accountInfo.Account,
					FullName: MPassengerInfo.FullName,
					LastNameEn: MPassengerInfo.LastNameEn,
					FirstNameEn: MPassengerInfo.FirstNameEn,
					Birthday: that.BirthDay,
					Nationality: MPassengerInfo.Nationality,
					Sex: MPassengerInfo.Sex,
					PersonCode: that.editPesonCode,
					mobile: that.mobile,
					//IsSelf: values.IsSelf,
				};
				console.log(parm);
				memberApi.SaveBookPassenger(parm, function(result) {
					that.btnLoading = false;
					if (result.code == 0) {
						that.showChoose = false;
						getOftenPassengerList();
					} else {
						that.$alert(result.msg);
					}

				})
			}
		}
	};

	//常旅客信息
	function getOftenPassengerList() {
		that.showLoading = true;
		var Book_PassengerList = null;
		// var Book_PassengerList = storagehelper.getPassengerListStorage(IsChinaCity);
		let parm = {
			Account: that.accountInfo.Account,
			isBooke: true,
			IsChinaCity: that.isChinaCity
		};
		memberApi.GetOftenPassengerList(parm, function(result) {
			that.showLoading = false;
			console.log(result);
			if (result.code == 0) {
				var PassengerList = JSON.parse(result.data.PassengerList);

				if (PassengerList == '' || PassengerList == null || PassengerList.length == 0) {
					// uni.showModal({
					// 	title: '提示',
					// 	content: '您当前还未添加旅客,是否添加？',
					// 	success: function(res) {
					// 		if (res.confirm) {
					// 			jumpEdit('');
					// 			return;
					// 		} else {
					// 		}
					// 	}
					// });
				} else {
					for (let item in PassengerList) {
						let checked = false;

						if (Book_PassengerList != null && Book_PassengerList != '') {
							var temp_Arr = Book_PassengerList.filter(function(item_list) {
								return item_list.Man_id === PassengerList[item].PersonCode;
							});
							checked = temp_Arr.length > 0;
						}

						PassengerList[item].checked = checked;
						PassengerList[item].PassengerAgeSectionName = PassengerList[item].PassengerAgeSection ==
							'CHD' ? '小孩' : '成人';
					}
					console.log(PassengerList);
					that.PassengerList = PassengerList;
				}
			}
		});
	}

	function getMPassengerInfo(PersonCode) {
		that.proofItem = '';
		that.MPassengerInfo = '';
		let parm = {
			PersonCode: PersonCode,
			isBooke: true,
			IsChinaCity: that.isChinaCity
		};
		memberApi.GetMPassengerInfo(parm, function(result) {
			// that.showLoading = false;
			console.log(result);
			if (result.code == 0) {
				that.CertificateTypeList = result.data.CertificateTypeList;
				that.MPassengerInfo = result.data.MPassengerInfo;
				that.CertificateTypeIndex = result.data.CertificateTypeIndex;
				that.BirthDay = result.data.BirthDay;
				that.mobile = result.data.phone;
				// for (let item in CertificateTypeList) {
				// 	CertificateTypeList[item].checked = false;
				// }
				that.proofItem = that.CertificateTypeList[that.CertificateTypeIndex];

			} else {
				console.log(result.msg);
			}
			console.log(that.proofItem);

		});
	}
</script>

<style lang="scss">
	.addperson {
		.add-person-btn {
			position: absolute;
			top: 22px;
			left: 144px;
		}
	}

	.type-left {
		display: inline-block;
		min-width: 120px;
		margin-right: 10px;
		text-align: right;
	}
</style>
