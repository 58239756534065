<template>
	<el-table :data="innerData">
		
		<el-table-column  v-for="(item,index) in outData"  :key="index" :prop="item.prop" :label="item.label" :width="item.width">
		 <template slot-scope="scope">
		  <el-row type="flex" v-if="index==0" align="middle">
		   <el-input v-model="scope.row.name[0].lastName" placeholder="姓(last name)" size="small"></el-input>
		   <span class="ml10 mr10">/</span>
		   <el-input v-model="scope.row.name[0].firstName" placeholder="名(first name)" size="small"></el-input>
		  </el-row>
		  <el-select  v-if="index==1" v-model="scope.row.sex" size="small" placeholder="请选择" @change="changeCode" @blur="changeNum(scope.$index,index)">
		     <el-option
		       v-for="sexItem in options2"
		       :key="sexItem.value"
		       :label="sexItem.label"
		       :value="sexItem.value">
		     </el-option>
		   </el-select>
		   <el-row type="flex" v-if="index==2" align="middle">
		     <el-input v-model="scope.row.country" placeholder="必填" size="small"></el-input>
		   </el-row>
		   <el-date-picker
		         style="width:100%"
		         @change='changeDate'
		         v-if="index==3"
		         v-model="scope.row.date"
		         type="date"
				 size="small"
		         placeholder="选择日期">
		    </el-date-picker>
			<el-select  v-if="index==4" size="small" v-model="scope.row.idType"  placeholder="请选择" @change="changeCode" @blur="changeNum(scope.$index,index)">
			   <el-option
			     v-for="sexItem in options3"
			     :key="sexItem.value"
			     :label="sexItem.label"
			     :value="sexItem.value">
			   </el-option>
			 </el-select>
			 <el-row type="flex" v-if="index==5" align="middle">
			   <el-input v-model="scope.row.exp" size="small" placeholder="必填"></el-input>
			 </el-row>
			 <el-date-picker
			       style="width:100%"
			       @change='changeDate'
			       v-if="index==6"
			       v-model="scope.row.exp"
			       type="date"
				   size="small"
			       placeholder="选择日期">
			  </el-date-picker>
			  <el-row type="flex" v-if="index==7" align="middle">
			   <el-col :span="6">
			   <el-input v-model="scope.row.phone[0].area" placeholder="选填" size="small"></el-input>
			   </el-col>
			   <span class="ml10 mr10">-</span>
			   <el-col :span="10">
			   <el-input v-model="scope.row.phone[0].phone" placeholder="选填" size="small"></el-input>
			   </el-col>
			   <el-col :span="8">
			   <span class="ml10 mr10 delete">删除</span>
			   </el-col>
			  </el-row>
		  </template>
		</el-table-column>
	</el-table>
</template>

<script>
	import moment from 'moment'
	export default {
		 name: 'Table',
		 props:['innerData','outData','type'],
		 data() {
			     return {
					input:'',
				    options2: [{
				             value: '男',
				             label: '男'
				           }, {
				             value: '女',
				             label: '女'
				           },],
				    value2: '',
					options3: [{
					         value: '护照',
					         label: '护照'
					       }, {
					         value: '军人证',
					         label: '军人证'
					       },{
					         value: '回乡证',
					         label: '回乡证'
					       },
						   {
						     value: '台胞证',
						     label: '台胞证'
						   }, {
						     value: '港澳通行证',
						     label: '港澳通行证'
						   },{
						     value: '其他证件',
						     label: '其他证件'
						   },],
					value3: ''
			     };
			   },
			   mounted(){
			   		this.setSex();	 
			   },
		  methods: {
			  changeDate(value){
				  moment(value).format('YYYY-MM-DD');
				  console.log(moment(value).format('YYYY-MM-DD'));
			  },
			  changeCode(sexvalue){
				  this.$emit('clickSex',sexvalue);
			  },
			  changeNum(e,f){
				  this.$emit('clickSexChange',e,f);
				  console.log(e,f);
			  },
		      clickAction(){
		        this.$emit('clickHeadAction',this.titleRight,this.widthNum);
		      },
			  clickDel(){ 
				  this.$emit('clickDelAction',this.titleRight,this.widthNum);
		      }
		  },
		  created(){
		              // this.$slots 包含所有插槽使用的数组 在改测试中返回 {default: Array(2), up: Array(4)}
		              // 在父组件中使用默认插槽2次，up插槽4次
		              // this.$slots.default 返回默认插槽的数组 {default: Array(2)} 
		              // elment-ui el-button 中的用法  <span v-if="$slots.default"><slot></slot></span>
		              console.log(this.$slots, this.$slots.default.length, 'slots');
		          }
	}
</script>

<style>
</style>
