<template>
	<div class="flight-listbox" style="">
		<div class="serch-loading-img text-c">
			<img src="../assets/serch_loading.gif" :style="big==true?'width:120px':'width:30px'" />
		</div>
	</div>
</template>

<script>
	export default {
		name: 'loading',
		props: {
			msg: String,
			big:String
		},
		data() {
			return {}
		}
	}
</script>

<style>
</style>
