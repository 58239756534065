<template>
	<div class="flight-listbox" style="">
		<div class="feihuoLoading">
			<div class="serch-loading-img mb10">
				<span class="iconfont icon-dingdan" style="font-size:56px;color:#999;"></span>
			</div>
			<p class="mt5 ft16">{{msg}}</p>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'personNoData',
		props: {
			msg:String,
		},
		data() {
			return {}
		}
	}
</script>

<style>
</style>
